<script setup lang="ts">
import {useNotifications, useProductWishlist} from "#imports";
import {ApiClientError} from "@shopware/api-client";
import type {Schemas} from "#shopware";
import {defu} from "defu";
import {useCmsTranslations} from "@shopware/composables";
const { pushSuccess, pushError, pushWarning } = useNotifications();
const { t } = useI18n();

const props = defineProps<{
  product: Schemas["Product"];
  class?: any;
}>();

type Translations = {
  product: {
    addedToWishlist: string;
    reason: string;
    cannotAddToWishlist: string;
  };
};

let translations: Translations = {
  product: {
    addedToWishlist: "has been added to wishlist.",
    reason: "Reason",
    cannotAddToWishlist: "cannot be added to wishlist.",
  },
};

translations = defu(translations, useCmsTranslations()) as Translations;

const { addToWishlist, removeFromWishlist, isInWishlist } = useProductWishlist(
    props.product.id,
);

const toggleWishlistProduct = async () => {
  if (!isInWishlist.value) {
    try {
      await addToWishlist();
      return pushSuccess(`<b>${props.product?.translated?.name}</b>` + t("product.messages.addedToWishlist"));
    } catch (error) {
      if (error instanceof ApiClientError) {
        const reason = error.details.errors?.[0]?.detail
            ? `${translations.product.reason}: ${error.details.errors?.[0]?.detail}`
            : "";
        return pushError(
            `<b>${props.product?.translated?.name}</b>` + t("product.messages.annotAddToWishlist") + `\n${reason}`
        );
      }
    }
  }
  pushWarning(
      `<b>${props.product?.translated?.name}</b> ` + t("product.messages.removedFromWishlist")
  );
  removeFromWishlist();
};
</script>

<template>
  <a href="javascript:void(0);" @click="toggleWishlistProduct()" :class="props.class">
    <i v-if="isInWishlist" class="fa-solid fa-heart"></i>
    <i v-else class=" fa-regular fa-heart"></i>
  </a>
</template>
